import logo from "./logo.svg";
import "./App.css";
import { Form } from "formik";
import HomePage from "./Pages/HomePage/HomePage";
import LoginPage from "./Pages/LoginPage/LoginPage";


function App() {
  return (
    <div className="App">
      {/* <HomePage /> */}
      <LoginPage />

      
    </div>
  );
}

export default App;
